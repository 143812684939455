import req from './request'

const DEFAULT_ENDPOINTS = {
    conversion: '/sms-conversion-event',   
    event: '/sms-conversion-event',
    reference: '/sms-conversion-event/reference',
    view: '/sms-conversion-event',
    templateByCompanyChatPlatforms: '/sms-conversion-event/template-by-company-chat-platforms',
}

export default {
    conversion(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.conversion, param)
    },
    event(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.event, param)
    },
    reference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    view(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.view, param)
    },
    templateByCompanyChatPlatforms(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.templateByCompanyChatPlatforms, param)
    }
}